import React from "react";
import { Navigation } from "../navigation.jsx";
import { useParams } from "react-router-dom";
import { GetText } from "../../data/lang";
import { Footer } from "../Footer";
import { Helmet } from "react-helmet";

export const MukReview = () => {

    const { lng } = useParams();
    let text = GetText();

    return (
        <div>
            <Helmet htmlAttributes={{ lang: lng }}>
                <title>MUK | {text.MukReview.title}</title>
                <meta name="description" content={text.MukReview.title} />
                <meta name="keywords" content='мук, muk, mukreview, журнал' />

            </Helmet>
            <Navigation lng={lng} />
            <div className="muk-review menu-indent">
                <div className="muk-review_title">
                    <div className="container">
                        <div className='col-md-12'>
                            <h2>{text.MukReview.title}</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="review-block col-md-12">
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #1
                            </p>
                            <a title="muk-review-1" href="https://muk.group/download/muk-review/1/muk-review-1.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/1.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #2
                            </p>
                            <a title="muk-review-2" href="https://muk.group/download/muk-review/2/muk-review-2.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/2.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #3
                            </p>
                            <a title="muk-review-3" href="https://muk.group/download/muk-review/3/muk-review-3.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/3.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #4
                            </p>
                            <a title="muk-review-4" href="https://muk.group/download/muk-review/4/muk-review-4.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/4.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #5
                            </p>
                            <a title="muk-review-5" href="https://muk.group/download/muk-review/5/muk-review-5.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/5.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #6
                            </p>
                            <a title="muk-review-6" href="https://muk.group/download/muk-review/6/muk-review-6.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/6.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #7
                            </p>
                            <a title="muk-review-7" href="https://muk.group/download/muk-review/7/muk-review-7.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/7.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #8
                            </p>
                            <a title="muk-review-8" href="https://muk.group/download/muk-review/8/muk-review-8.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/8.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #9
                            </p>
                            <a title="muk-review-9" href="https://muk.group/download/muk-review/9/muk-review-9.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/9.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #10
                            </p>
                            <a title="muk-review-10" href="https://muk.group/download/muk-review/10/muk-review-10.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/10.jpeg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #11
                            </p>
                            <a title="muk-review-11" href="https://muk.group/download/muk-review/11/muk-review-11.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/11.jpg" title="review" alt="review" /></a>
                        </div>
                        <div className="review-block_item">
                            <p className="review-name">
                                {text.MukReview.vupysk} #12
                            </p>
                            <a title="muk-review-11" href="https://muk.group/download/muk-review/12/muk-review-12.pdf" target="_blank" rel="noreferrer"  ><img className="review-img" src="../../img/magazines/12.png" title="review" alt="review" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
            <Footer />
        </div>
    );
};
