import React, { useContext, useEffect, useState } from "react";
import { Navigation } from "../navigation.jsx";
import { Link, useParams } from 'react-router-dom';
import { StateContext } from "../../context/GlobalState";
import { Footer } from "../Footer";
import { SolutionForVendors } from "../SolutionForVendors";
import { GetText } from "../../data/lang";
import { Helmet } from "react-helmet";

export const VendorDetail = () => {

    // получение данных
    const { vendorLink, lng } = useParams();
    const [vendorDetail, setVendorDetail] = useState([]);
    const { countryList, lang, geo } = useContext(StateContext);

    // фильтр Азербайджана и Армении
    let countryListMod = countryList;
    if (geo === "az") { countryListMod = countryList.filter((c) => c.iso !== "am"); }
    if (geo === "am") { countryListMod = countryList.filter((c) => c.iso !== "az"); }

    //Костыль для вакансий
    //countryListMod = countryListMod.filter((c) =>  c.iso !== "bg");
    //countryListMod = countryListMod.filter((c) =>  c.iso !== "rs");

    //сортировка стран по алфавиту
    if (lang === "ua") { countryListMod = countryListMod.sort(function (a, b) { return a.name_ua.localeCompare(b.name_ua) }) }
    if (lang === "ru") { countryListMod = countryListMod.sort(function (a, b) { return a.name_ru.localeCompare(b.name_ru) }) }
    if (lang === "en") { countryListMod = countryListMod.sort(function (a, b) { return a.name_en.localeCompare(b.name_en) }) }
    if (lang === "az") { countryListMod = countryListMod.sort(function (a, b) { return a.name_en.localeCompare(b.name_en) }) }



    useEffect(() => {
        fetch(`https://muk.group/backend/api/vendorDetail/read.php?vendor=${vendorLink}`)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setVendorDetail(data)
                //console.log(data)

            ) // eslint-disable-next-line
    }, [])

    let text = GetText();

    return (
        <div>
            <div>
                <Navigation lng={lng} />
                <div className="vendor-detail menu-indent">
                    {vendorDetail ? vendorDetail.map((vendor, i) =>
                        <div key={i}>

                            <Helmet htmlAttributes={{ lang: lang }}>
                                <title>MUK | {vendor.name}  | {text ? text.VendorDetail.title : ""}</title>
                                {lang === "ua" ? <meta name="description" content={vendor.description_ua.substr(0, 200)} /> : ""}
                                {lang === "ru" ? <meta name="description" content={vendor.description_ru.substr(0, 200)} /> : ""}
                                {lang === "en" ? <meta name="description" content={vendor.description_en.substr(0, 200)} /> : ""}
                                {lang === "az" ? <meta name="description" content={vendor.description_az.substr(0, 200)} /> : ""}
                                {lang === "al" ? <meta name="description" content={vendor.description_al.substr(0, 200)} /> : ""}
                                {lang === "am" ? <meta name="description" content={vendor.description_am.substr(0, 200)} /> : ""}
                                {lang === "ge" ? <meta name="description" content={vendor.description_ge.substr(0, 200)} /> : ""}
                                {lang === "kz" ? <meta name="description" content={vendor.description_kz.substr(0, 200)} /> : ""}
                                {lang === "kg" ? <meta name="description" content={vendor.description_kg.substr(0, 200)} /> : ""}
                                {lang === "mk" ? <meta name="description" content={vendor.description_mk.substr(0, 200)} /> : ""}
                                {lang === "md" ? <meta name="description" content={vendor.description_md.substr(0, 200)} /> : ""}
                                {lang === "mn" ? <meta name="description" content={vendor.description_mn.substr(0, 200)} /> : ""}
                                {lang === "si" ? <meta name="description" content={vendor.description_si.substr(0, 200)} /> : ""}
                                {lang === "tj" ? <meta name="description" content={vendor.description_tj.substr(0, 200)} /> : ""}
                                {lang === "tr" ? <meta name="description" content={vendor.description_tr.substr(0, 200)} /> : ""}
                                {lang === "tm" ? <meta name="description" content={vendor.description_tm.substr(0, 200)} /> : ""}
                                {lang === "uz" ? <meta name="description" content={vendor.description_uz.substr(0, 200)} /> : ""}
                                {lang === "hr" ? <meta name="description" content={vendor.description_hr.substr(0, 200)} /> : ""}
                                {lang === "rs" ? <meta name="description" content={vendor.description_rs.substr(0, 200)} /> : ""}
                                {lang === "bg" ? <meta name="description" content={vendor.description_bg.substr(0, 200)} /> : ""}
                                <meta name="keywords" content={vendor.name + ', vendor, muk'} />

                            </Helmet>
                            <div className="vendor-detail_top">
                                <div className="container">
                                    <div className="vendor-detail_left">
                                        <h3 className="vendor-name">{vendor.name}</h3>
                                        <p className="vendor-authorized">{text ? text.VendorDetail.title : ""}</p>
                                    </div>
                                    <div className="vendor-detail_right">
                                        <svg className='team-img' viewBox="0 0 145 55" width="145" height="55">
                                            <rect x="1" y="1" width="143" height="53" rx="6" ry="6" stroke="rgb(179, 179, 179)" stroke-width="1" fill="white" />
                                            <image href={`/img/vendors/${vendor.img}`} x="0" y="0" width="145" height="55" />
                                        </svg>

                                        {/*<img className="vendor-img" title={vendor.name}
                                         src={`/img/vendors/${vendor.img}`}
                                         alt={`logo-vendor-${vendor.name}`}/>*/}
                                        <p><a target="_blank" rel="noreferrer" className="vendor-link" title={vendor.url} href={vendor.url}>{vendor.url}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="vendor-country">
                                <div className="container">
                                    <div className="vendor-country_block">
                                        <ul className="d-flex flex-wrap">

                                            {countryListMod ? countryListMod.map((country, i) =>
                                                vendorDetail[0].avaliable_counteies.split(',').includes(country.id) ?
                                                    <li key={i} className="list-group-item list-group-item-up">
                                                        {lang === "ua" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_ua} alt={country.name_ua} src={`/img/flags/${country.img}`} />{country.name_ua}</Link> : ""}
                                                        {lang === "ru" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_ru} alt={country.name_ru} src={`/img/flags/${country.img}`} />{country.name_ru}</Link> : ""}
                                                        {lang === "en" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_en} alt={country.name_en} src={`/img/flags/${country.img}`} />{country.name_en}</Link> : ""}
                                                        {lang === "az" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_az} alt={country.name_az} src={`/img/flags/${country.img}`} />{country.name_az}</Link> : ""}
                                                        {lang === "al" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_al} alt={country.name_al} src={`/img/flags/${country.img}`} />{country.name_al}</Link> : ""}
                                                        {lang === "am" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_am} alt={country.name_am} src={`/img/flags/${country.img}`} />{country.name_am}</Link> : ""}
                                                        {lang === "ge" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_ge} alt={country.name_ge} src={`/img/flags/${country.img}`} />{country.name_ge}</Link> : ""}
                                                        {lang === "kz" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_kz} alt={country.name_kz} src={`/img/flags/${country.img}`} />{country.name_kz}</Link> : ""}
                                                        {lang === "kg" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_kg} alt={country.name_kg} src={`/img/flags/${country.img}`} />{country.name_kg}</Link> : ""}
                                                        {lang === "mk" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_mk} alt={country.name_mk} src={`/img/flags/${country.img}`} />{country.name_mk}</Link> : ""}
                                                        {lang === "md" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_md} alt={country.name_md} src={`/img/flags/${country.img}`} />{country.name_md}</Link> : ""}
                                                        {lang === "mn" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_mn} alt={country.name_mn} src={`/img/flags/${country.img}`} />{country.name_mn}</Link> : ""}
                                                        {lang === "si" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_si} alt={country.name_si} src={`/img/flags/${country.img}`} />{country.name_si}</Link> : ""}
                                                        {lang === "tj" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_tj} alt={country.name_tj} src={`/img/flags/${country.img}`} />{country.name_tj}</Link> : ""}
                                                        {lang === "tr" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_tr} alt={country.name_tr} src={`/img/flags/${country.img}`} />{country.name_tr}</Link> : ""}
                                                        {lang === "tm" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_tm} alt={country.name_tm} src={`/img/flags/${country.img}`} />{country.name_tm}</Link> : ""}
                                                        {lang === "uz" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_uz} alt={country.name_uz} src={`/img/flags/${country.img}`} />{country.name_uz}</Link> : ""}
                                                        {lang === "hr" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_hr} alt={country.name_hr} src={`/img/flags/${country.img}`} />{country.name_hr}</Link> : ""}
                                                        {lang === "rs" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_rs} alt={country.name_rs} src={`/img/flags/${country.img}`} />{country.name_rs}</Link> : ""}
                                                        {lang === "bg" ? <Link to={`/${lang}/country/${country.iso}`} > <img className="flag-ico" title={country.name_bg} alt={country.name_bg} src={`/img/flags/${country.img}`} />{country.name_bg}</Link> : ""}
                                                    </li>
                                                    : ""
                                            ) : "Loading..."}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div className="vendor-detail_bottom">
                                {lang === "ua" ? <div className="container">{vendorDetail[0].description_ua ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_ua }} /> : ""}</div> : ""}
                                {lang === "ru" ? <div className="container">{vendorDetail[0].description_ru ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_ru }} /> : ""}</div> : ""}
                                {lang === "en" ? <div className="container">{vendorDetail[0].description_en ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_en }} /> : ""}</div> : ""}
                                {lang === "az" ? <div className="container">{vendorDetail[0].description_az ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_az }} /> : ""}</div> : ""}
                                {lang === "al" ? <div className="container">{vendorDetail[0].description_al ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_al }} /> : ""}</div> : ""}
                                {lang === "am" ? <div className="container">{vendorDetail[0].description_am ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_am }} /> : ""}</div> : ""}
                                {lang === "ge" ? <div className="container">{vendorDetail[0].description_ge ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_ge }} /> : ""}</div> : ""}
                                {lang === "kz" ? <div className="container">{vendorDetail[0].description_kz ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_kz }} /> : ""}</div> : ""}
                                {lang === "kg" ? <div className="container">{vendorDetail[0].description_kg ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_kg }} /> : ""}</div> : ""}
                                {lang === "mk" ? <div className="container">{vendorDetail[0].description_mk ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_mk }} /> : ""}</div> : ""}
                                {lang === "md" ? <div className="container">{vendorDetail[0].description_md ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_md }} /> : ""}</div> : ""}
                                {lang === "mn" ? <div className="container">{vendorDetail[0].description_mn ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_mn }} /> : ""}</div> : ""}
                                {lang === "si" ? <div className="container">{vendorDetail[0].description_si ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_si }} /> : ""}</div> : ""}
                                {lang === "tj" ? <div className="container">{vendorDetail[0].description_tj ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_tj }} /> : ""}</div> : ""}
                                {lang === "tr" ? <div className="container">{vendorDetail[0].description_tr ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_tr }} /> : ""}</div> : ""}
                                {lang === "tm" ? <div className="container">{vendorDetail[0].description_tm ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_tm }} /> : ""}</div> : ""}
                                {lang === "uz" ? <div className="container">{vendorDetail[0].description_uz ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_uz }} /> : ""}</div> : ""}
                                {lang === "hr" ? <div className="container">{vendorDetail[0].description_hr ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_hr }} /> : ""}</div> : ""}
                                {lang === "rs" ? <div className="container">{vendorDetail[0].description_rs ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_rs }} /> : ""}</div> : ""}
                                {lang === "bg" ? <div className="container">{vendorDetail[0].description_bg ? <div dangerouslySetInnerHTML={{ __html: vendorDetail[0].description_bg }} /> : ""}</div> : ""}
                            </div>
                            {
                                vendorDetail[0].id === '225' ?
                                    <div className="container" style={{ marginBottom: '50px' }}>
                                        <a href="https://cloud.muk.group/blog_AWS/" target="_blank" className="btn btn-custom btn-lg">More about aws</a>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    ) : "Loading..."}
                </div>
                <SolutionForVendors />
                <Footer />
            </div>
        </div>
    );
};
